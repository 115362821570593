import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import i18n from './i18n/i18n'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import '@/assets/scss/quill-editor.css'

import { localize } from 'vee-validate'
localize('sl')

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//FontAwesome
library.add(fas)
Vue.component('fa', FontAwesomeIcon)

Vue.prototype.$dayjs = dayjs

Vue.prototype.$numberRounding = function(val, numOfDecimals = 2) {
    if (!numOfDecimals || numOfDecimals < 2) {
        numOfDecimals = 2
    } else if (numOfDecimals > 6) {
        numOfDecimals = 6
    }

    const numberToAdd = Math.trunc(5000 / Math.pow(10, numOfDecimals - 2))

    const num = Number(Number(val).toFixed(0))
    let numText = ''

    if (num >= 0) {
        numText = `${num + numberToAdd}`.padStart(7, '0')
    } else {
        numText = `${(num - numberToAdd) * -1}`.padStart(7, '0')
        numText = `-${numText}`
    }


    if (numText.slice(-(6 - numOfDecimals)) === '0'.repeat(6 - numOfDecimals)) {
        const lastDigit = numText.charAt(numText.length - (7 - numOfDecimals))
        switch (lastDigit) {
            case '1':
            case '3':
            case '5':
            case '7':
            case '9':
                numText = numText.slice(0, -(7 - numOfDecimals)) + (lastDigit - 1) + numText.slice(-(6 - numOfDecimals))
        }
    }

    if (numOfDecimals < 6) {
        return `${numText.slice(0, -6)}.${numText.slice(-6, -6 + numOfDecimals)}`
    } else {
        return `${numText.slice(0, -6)}.${numText.slice(-6)}`
    }
}

Vue.prototype.$amountFilter = function(filter, value, filterText) {
    if (!filterText) return true
    filterText = filterText.replace(',', '.')
    value = Vue.prototype.$numberRounding(value)
    const stringValue = value.toString()
    return stringValue.includes(filterText)
}

Vue.prototype.$datesInRange = function(filter, value, filterText) {
    const date = new Date(value.toUpperCase())
    const dates = filterText.toUpperCase().split(' || ')
    const start = new Date(dates[0])
    const end = new Date(dates[1])

    return date > start && date < end
}

Vue.prototype.$prettyNumberRounding = function(val, numOfDecimals = 2) {
    if (!numOfDecimals || numOfDecimals < 2) {
        numOfDecimals = 2
    } else if (numOfDecimals > 6) {
        numOfDecimals = 6
    }

    const numberToAdd = Math.trunc(5000 / Math.pow(10, numOfDecimals - 2))

    const num = Number(Number(val).toFixed(0))
    let numText = ''

    if (num >= 0) {
        numText = `${num + numberToAdd}`.padStart(7, '0')
    } else {
        numText = `${(num - numberToAdd) * -1}`.padStart(7, '0')
        numText = `-${numText}`
    }


    if (numText.slice(-(6 - numOfDecimals)) === '0'.repeat(6 - numOfDecimals)) {
        const lastDigit = numText.charAt(numText.length - (7 - numOfDecimals))
        switch (lastDigit) {
            case '1':
            case '3':
            case '5':
            case '7':
            case '9':
                numText = numText.slice(0, -(7 - numOfDecimals)) + (lastDigit - 1) + numText.slice(-(6 - numOfDecimals))
        }
    }

    if (numOfDecimals < 6) {
        return `${numText.slice(0, -6)}.${numText.slice(-6, -6 + numOfDecimals)}`
    } else {
        return `${numText.slice(0, -6)}.${numText.slice(-6)}`
    }
}

dayjs.extend(require('dayjs/plugin/utc'))

Vue.prototype.dayjs = dayjs

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$NilObjectId = '000000000000000000000000'

Vue.prototype.$printError = function(error) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: `${i18n.t('print.message.error')}`,
            icon: 'DangerOctagonIcon',
            text: error,
            variant: 'danger'
        }
    })
}

Vue.prototype.$printWarning = function(message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: `${i18n.t('print.message.warning')}`,
            icon: 'AlertTriangleIcon',
            text: message,
            variant: 'warning'
        }
    })
}

Vue.prototype.$printSuccess = function(message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: `${i18n.t('print.message.successful')}`,
            icon: 'CheckCircleIcon',
            text: message,
            variant: 'success'
        }
    })
}

Vue.prototype.$confirmDialog = function(message, yesButtonMessage = `${i18n.t('general.yes')}`, noButtonMessage = `${i18n.t('general.no')}`) {
    return this.$bvModal.msgBoxConfirm(message, {
        size: 'sm',
        okVariant: 'danger',
        okTitle: yesButtonMessage,
        cancelTitle: noButtonMessage,
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
    })
}

Vue.prototype.$confirmDialogWithBody = function(title, message, yesButtonMessage = `${i18n.t('general.yes')}`, noButtonMessage = `${i18n.t('general.no')}`) {
    return this.$bvModal.msgBoxConfirm(message, {
        title,
        size: 'sm',
        okVariant: 'danger',
        okTitle: yesButtonMessage,
        cancelTitle: noButtonMessage,
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
    })
}

Vue.prototype.$supported_phone_formats = '+386 51 123 456<br>00386 51 123 456<br>051 123 456'


async function init() {

    const store = (await import('@/store')).default
    const App = (await import('@/App.vue')).default
    await import('@/libs/permissions')
    await import('@/libs/axios.js')
    await import('@/libs/tiers')

    // let login = true

    try {
        if (localStorage.userData) {
            await Vue.prototype.$http.get('/api/client/v1/ping')

            await store.dispatch('user/updateUserData', JSON.parse(localStorage.userData))

            await store.dispatch('user/setLoginStatus', true)

            const res = await Vue.prototype.$http.get('/api/client/v1/users/permissions')
            const permissions = res.data ?? []

            const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
            const clientData = res1.data ?? {}

            store.state.user.userPermissions = permissions
            store.state.client.clientData = clientData
        } else {
            await store.dispatch('user/setLoginStatus', false)
        }
    } catch (err) {
        if (err.response.status === 403) {
            if (err.response.data === 'user no client') {
                await store.dispatch('user/setUserNoClients')
            } else if (err.response.data === 'user missing client') {
                await store.dispatch('user/selectFirstClient')
            }
        } else {
            await store.dispatch('user/setLoginStatus', false)
        }
    }

    const router = (await import('@/router')).default

    const currentRoute = router.history._startLocation
    if (router.options && router.options.routes) {

        const routeInstance = await router.resolve(currentRoute).resolved

        if (routeInstance && (!routeInstance.meta || routeInstance.meta.requiresAuth === true)) {
            router.push({name: 'login'}).catch()
        }
    }

    await import('@/libs/sentry')

    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
}

init()

